import React from 'react';
import './Services.scss';

function Services() {
  return (
    <div className='services-wrap'>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2>Event Production</h2>
              <p>Experience the magic of bespoke event production with our seasoned team, where your aspirations meet our expertise. From the drawing board to the day of the event, we promise a journey of creativity, meticulous planning, and impeccable execution. Every aspect of your event will be handled with unparalleled attention to detail, guaranteeing an unforgettable experience that perfectly aligns with your vision.</p>
            </div>
            <div className='col-md-6'>
              <h2>Artist Management</h2>
              <p>Unlock the full potential of your event with our seasoned artist management professionals, who are adept at sourcing and securing the best talent to fit your unique requirements. Our extensive network of artists and performers is at your disposal, ready to be tailored to the theme and atmosphere of your event. We commit to a collaborative approach, ensuring the talent we secure not only meets but surpasses your expectations for a truly standout event.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>Marketing</h2>
              <p>Discover the power of targeted marketing with our skilled team, ready to craft a marketing strategy that shines a spotlight on your event. Our comprehensive approach ensures that from online buzz to offline chatter, your event becomes the talk of the town. By aligning our innovative marketing techniques with your event's unique selling points, we work together to create a campaign that not only attracts but also excites your desired audience, driving the success of your event.</p>
            </div>
            <div className='col-md-6'>
              <h2>VIP Services</h2>
              <p>Indulge in the ultimate VIP experience with our specialized services designed to pamper and impress. Our VIP attendees are treated to a world of exclusive privileges, including priority access, personalized concierge service, and unparalleled comfort. We understand the importance of attention to detail and strive to exceed expectations, ensuring all needs are not just met but anticipated. With FullSend Productions, your VIP experience will be nothing short of extraordinary, marked by luxury, privacy, and meticulous care tailored to your every desire.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;