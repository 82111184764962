import React from 'react';
import './Contact.scss';

function Contact() {
  return (
    <div className='contact-wrap'>
      <div className='content'>
        <h1>Contact Us</h1>
        <p>For all inquiries, please email us at <a href="mailto:info@fullsend.global">info@fullsend.global</a> and we will get back to you as soon as possible.</p>
      </div>
    </div>
  );
}

export default Contact;