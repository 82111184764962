import React from 'react';
import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className='container'>
        <div className="py-3 text-light">
          &copy; 2024 FullSend Productions
        </div>
      </div>
    </footer>
  );
}

export default Footer;
