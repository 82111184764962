import React from 'react';
import './Home.scss';

function Home() {
  return (
    <div className='wrap'>
      <div className='content'>
        <p>At FullSend Productions, we believe that true hospitality combines meticulous planning with a touch of magic. Our ethos is built on creating a welcoming atmosphere where every guest feels an unparalleled sense of belonging.</p>
        <p>Through our innovative approach to event production and artist management, we ensure that each event is a testament to our dedication to excellence.</p>
      </div>
    </div>
  );
}

export default Home;