import React from 'react';

function About() {
  return (
    <div className='wrap'>
      <div className='content'>
        <p>At FullSend Productions, we believe that true hospitality combines meticulous planning with a touch of magic. Our ethos is built on creating a welcoming atmosphere where every guest feels an unparalleled sense of belonging.</p>
        <p>Through our innovative approach to event production and artist management, we ensure that each event is a testament to our dedication to excellence.</p>
        <p>Our team is adept at navigating the ever-evolving landscape of technology, incorporating the latest trends in web design and digital promotion to elevate your event's online footprint. We specialize in artist and talent management, focusing on fostering growth and maximizing exposure to bring our clients' creative visions to life.</p>
        <p>Partnerships are the cornerstone of our success. We collaborate with leading providers of sound systems, lighting solutions, and bar services to ensure that every aspect of your event is executed flawlessly. With FullSend Productions, you gain access to a network of excellence, ensuring that from the first note to the last call, your event is in expert hands.</p>
      </div>
    </div>
  );
}

export default About;